/* eslint no-console:0 */
import Rails from '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"
import 'jquery'
import 'popper.js'
import 'bootstrap'
require('@nathanvda/cocoon')

import 'stylesheets/base'

// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
